.app {
  width: 100vw;
  height: 100vh;
  /* overflow: hidden; */
}

.app-header {
  display: flex;
  align-items: center;
  font-size: large;
  color: white;
  padding-left: 30px;
}

.open-nav-button {
  margin-right: 20px;
}

.app-nav {
  flex: auto;
  margin-left: 20px;
  min-width: 0;
}

.app-label {
  color: white;
}

.app-label:hover {
  color: lightgray;
}

.app-header-project-name {
  flex: auto;
  color: white;
  font-size: x-large;
}
