.io-node {
  width: 100px;
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 5px;
  background: rgb(100, 106, 196);
  text-align: center;
}

.operator-node {
  min-width: 100px;
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 5px;
  /* background: rgb(39, 104, 14); */
  /* background: rgb(111, 187, 81); */
  background: rgb(98, 173, 68);
  text-align: center;
}

.operator-node-params-form {
  padding-top: 10px;
}

.operator-node-form-item {
  text-align: start;
}

.subgraph-node,
.foreach-node {
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 5px;
  background: rgb(250, 157, 51);
  min-width: 100px;
  text-align: center;
}

.subgraph-node label {
  display: block;
  font-size: 12px;
  padding: 5px;
}

.foreach-node label {
  display: block;
  font-size: 12px;
  padding: 5px;
}

.react-flow__handle {
  width: 10px;
  height: 10px;
}

.multi-handle {
  background-color: blue;
}

.react-flow__edge-path,
.react-flow__connection-path {
  stroke-width: 4px;
}

.multi-edge .react-flow__edge-path {
  stroke: blue;
}

.multi-edge.selectable:hover .react-flow__edge-path,
.multi-edge.selectable.selected .react-flow__edge-path {
  stroke: cyan;
}

.error-edge .react-flow__edge-path {
  stroke: red;
}

.error-edge.selectable:hover .react-flow__edge-path,
.error-edge.selectable.selected .react-flow__edge-path {
  stroke: rgb(247, 86, 185);
}

.conditional-edge .react-flow__edge-path {
  stroke: orange;
}

.conditional-edge.selectable:hover .react-flow__edge-path,
.conditional-edge.selectable.selected .react-flow__edge-path {
  stroke: yellow;
}

.processed-edge .react-flow__edge-path {
  stroke: rgb(47, 121, 57);
}

.processed-edge.selectable:hover .react-flow__edge-path,
.processed-edge.selectable.selected .react-flow__edge-path {
  stroke: rgb(19, 202, 43);
}

.subgraph-select {
  min-width: 10em;
}

.subgraph-edit-link {
  padding-top: 5px;
}

.if-node {
  width: 100px;
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 5px;
  background: rgb(238, 196, 9);
  text-align: center;
}

.if-node-data-handle {
  left: 20px;
}

.if-node-condition-handle {
  left: 80px;
  background-color: violet;
}

.if-node-true-handle {
  left: 20px;
  background-color: green;
}

.if-node-false-handle {
  left: 80px;
  background-color: red;
}
