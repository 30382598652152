.lpe-hidden {
  display: none;
}

.graph-tools-container {
  position: absolute;
  z-index: 10;
  margin-top: 30px;
}

.graph-editor-breadcrumb {
  padding-left: 30px;
  position: absolute;
  z-index: 5;
}

.project-list-container,
.graph-list-container {
  padding: 20px;
  width: 100%;
}

.project-list,
.graph-list {
  width: 100%;
}

.graph-list-rename-btn {
  margin-left: 10px;
}

.project-list-edit-btn,
.graph-list-edit-btn {
  color: black;
}

.project-list-edit-btn:hover,
.graph-list-edit-btn:hover {
  color: darkgray;
}

.projects-list-current-project {
  background-color: aqua;
  height: 100%;
}

.lpe-placeholder {
  height: 100%;
  width: 100%;
}

.graph-extra-tools {
  position: absolute;
  z-index: 5;
  bottom: 10px;
  left: 10px;
}

.graph-extra-tools > button {
  margin-right: 10px;
}

.graph-test-panel-container {
  position: absolute;
  width: 100%;
  height: 40%;
  bottom: 0;
  z-index: 10;
}

.test-panel {
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.test-panel > div {
  padding: 10px;
}

.test-panel textarea {
  resize: none;
  flex-grow: 1;
}

.test-panel-left-col {
  flex-grow: 1;
  flex-direction: column;
  display: flex;
}

.test-panel-middle-col {
  flex-grow: 1;
  flex-direction: column;
  display: flex;
}

.test-panel-middle-col textarea {
  flex-grow: 1;
}

.test-panel-right-col {
  flex-grow: 1;
  display: flex;
}

.test-panel-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
}

.project-settings-form {
  padding: 10px;
  max-width: 40em;
}

.auth-container {
  width: 100%;
  height: 100%;
  /* background-color: #d9d9d9; */
}

.token-auth-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.token-auth-form > div {
  margin-top: 12px;
  margin-bottom: 12px;
}

.import-dropzone {
  height: 20em;
  display: block;
}

.google-login-card {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.tasks-list-actions > button,
.tasks-list-actions > a {
  margin-right: 10px;
}

.project-list-controls > button {
  margin-right: 10px;
}
